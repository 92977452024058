import * as React from 'react'

import Page from '../components/Page'
import IndexLayout from '../layouts'
import { Box, Flex } from '@rebass/grid/emotion'
import PageContext from '../components/pageContext'
import { LocaleContext } from '../i18n'
import { Link, InlineLink } from '../components/Link'
interface PageTemplateProps extends PageContext {
  data: {
    site: {
      siteMetadata: {
        title: string
        description: string
        author: {
          name: string
          url: string
        }
      }
    }
    markdownRemark: {
      html: string
      excerpt: string
      frontmatter: {
        title: string
      }
    }
  }
}


const PageTemplate: React.SFC<PageTemplateProps> =
  ({ data, pageContext }) => {
    return <IndexLayout pageContext={pageContext}>
      <Page>
        <Flex
          alignItems="center"
          flexDirection="column"
        >
          <Box m="auto"
            pt={3}
            pl={4}
            pr={4}
          >
            <LocaleContext.Consumer>{({ t }) =>
              <div>
                <h2>{t('about')}</h2>
                <p dangerouslySetInnerHTML={{ __html: t('about.description') }} />

                <h3>{t('filmography.title')}</h3>
                <ul>
                  <li>Kiedy zamykam oczy {t('dir')} Marek Bukowski</li>
                  <li>Kamerdyner {t('dir')} F. Bajom</li>
                  <li>Zabusia {t('dir')} A. Wieczur - Bluszcz {<InlineLink href="https://vod.tvp.pl/website/zabusia,36023046" target="_blank">[link]</InlineLink>}</li>
                  <li>Służby Specjalne {t('dir')} P. Vega</li>
                  <li>Pamiętnik Pani Hanki {t('dir')} B. Lankosz</li>
                  <li>Tango {t('dir')} J. Jarocki</li>
                  <li>Generał {t('dir')} A. Jadowska</li>
                  <li>Zero {t('dir')} P. Borowski</li>
                  <li>Nadzieja {t('dir')} S. Mucha</li>
                  <li>Zorka {t('dir')} Ł. Wylężałek</li>
                  <li>Vinci {t('dir')} J. Machulski</li>
                  <li>Hamlet {t('dir')} Barczyk</li>
                </ul>
                <Link href="http://www.filmpolski.pl/fp/index.php?osoba=1144153">{t('filmography')}</Link>
                <Link href="https://pl.wikipedia.org/wiki/Kamilla_Baar-Kochańska">Wikipedia</Link>
                <Link href="https://www.facebook.com/kamilla.baar.31/">Facebook</Link>
                <Link href="mailto:baar.kochanska@gmail.com">{t('contact')}</Link>
              </div>
            }
            </LocaleContext.Consumer>
          </Box>
        </Flex>
      </Page>
    </IndexLayout >
  }

export default PageTemplate
